import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';

export default class KasesManageEditTfeLibraryRoute extends SearchRoute {
  @service router;
  @service session;

  layout = 'custom-aside';

  queryParams = {
    archived: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    query: {
      replace: true,
      refreshModel: true
    }
  };

  modelType = 'tfe/document';
  routeRegex = /authenticated.kases.manage.edit.tfe.library/;

  model(params) {
    const kase = this.modelFor('authenticated.kases.manage');

    return this.store.query('tfe/document', {
      archived: params.archived || this.defaultArchived,
      current_client: true,
      current_user: true,
      enabled: true,
      include: 'latest_revision',
      latest_revision_status: 'completed',
      kase_id: kase.id,
      page: params.page || this.defaultPage,
      query: params.query || this.defaultQuery
    });
  }

  setupController(controller) {
    super.setupController(...arguments);

    const kase = this.modelFor('authenticated.kases.manage');

    controller.set('kase', kase);
    controller.set('kase_id', kase.get('id'));
    controller.set('events', this.store.query('event', { kase_id: kase.id }));
    controller.set('inquiries', this.store.findAll('inquiry', { kase: kase.id }));
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      this.store.unloadAll('tfe/document');
    }
  }
}
