import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SelectionProductSummaryComponent extends Component {
  @service store;

  @tracked isEditingProd = false;
  @tracked required = null;
  @tracked showDialog = false;
  @tracked isInPackage = false;

  constructor() {
    super(...arguments);
    this.setIsInPackage();
  }

  get idKey() {
    return this.args.model.get('id');
  }

  async setIsInPackage() {
    const productId = this.args.model.get('id');
    const packageId = this.args.package.get('id'); // Assuming the package is passed in and has an id property

    const results = await this.store.query('v2/package_slot_product', {
      package_id: packageId,
      product_id: productId,
    });

    set(this, 'isInPackage', results.length > 0);
  }

  @action
  removeProduct() {
    this.args.onRemoveProduct(this.args.model);
    set(this, 'showDialog', false);
  }

  @action
  saveProduct() {
    set(this, 'isEditingProd', false);
    this.args.saveProduct(this.args.model);
  }

  @action
  revertProduct() {
    set(this, 'isEditingProd', false);
    this.args.revertProduct(this.args.model);
  }
}
