import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  'package.name': validator('presence', true),
  'package.invoiceCategoryId': validator('presence', true),
});

export default class AdminGeneralPriceListsPackagesEditController extends Controller.extend(
  Validations
) {
  @service flashes;
  @service invoiceCategories;
  @service intl;
  @service router;
  @service session;

  @tracked displayAddItemWell = false;
  @tracked newPackageSlot = null;
  @tracked showValidations = false;

  get pricingMethodAmountValidationType() {
    if (this.package.pricingMethod === 'fixed') {
      return this.intl.t('authenticated.admin.general-price-lists.edit.packages.edit.lists.finalTotal.validations.error.subtotal.types.finalTotal');
    }

    if (this.package.pricingMethod === 'amount') {
      return this.intl.t('authenticated.admin.general-price-lists.edit.packages.edit.lists.finalTotal.validations.error.subtotal.types.discount')
    }

    return '';
  }

  get productQueryParams() {
    return {
      general_price_list_id: this.generalPriceList.get('id'),
      archived: false,
    };
  }

  get flexibleItems() {
    return this.package.packageSlots.filter((ps) => ps.packageSlotType === 'flexible_items' && !ps.archivedAt && !ps.isNew);
  }

  get packageSlotProducts() {
    return this.package.packageSlotProducts.filter((psp) => {
      return psp.packageSlot && psp.packageSlot.get('packageSlotType') === 'item' && !psp.packageSlot.get('isNew')
    }).map((psp) => {
      return {
        packageSlotProduct: psp,
        packageSlot: psp.packageSlot,
        product: psp.product,
      }
    });
  }

  @computed('package.{total,productsTotal}')
  get totalWarning() {
    return this.package.total < this.package.productsTotal;
  }

  @equal('package.pricingMethod', 'normal') isNormal;

  get displayTotalWarningColor() {
    if (this.showValidations && !this.isValidPricingMethodAmount) {
      return 'red'
    } else if (this.totalWarning) {
      return 'yellow'
    } else {
      return null
    }
  }

  get isValidPricingMethodAmount () {
    if (this.package.pricingMethod === 'amount') {
      return this.package.discountedTotal > 0;
    } else if (this.package.pricingMethod === 'fixed') {
      return this.package.calculatedDiscount > 0;
    } else {
      return true;
    }
  }

  @action
  async saveNewPackageSlot() {
    set(this, 'displayAddItemWell', false);
    set(this, 'newPackageSlot', null);
  }

  @action
  async addNewItem() {
    set(this, 'newPackageSlot', this.store.createRecord('v2/package-slot', {
      packageSlotType: 'item',
      package: this.package,
    }));

    set(this, 'displayAddItemWell', true);
  }

  @action
  savePackage() {
    if (this.displayAddItemWell) {
      set(this, 'displayAddItemWell', false);
      this.newPackageSlot.rollbackAttributes();
    }

    this.validate().then(({ validations }) => {
      if (validations.get('isValid') && this.isValidPricingMethodAmount) {
        this.showValidations = false;
        this.package
          .save()
          .then(() => {
            this.router.transitionTo(
              'authenticated.admin.general-price-lists.edit.packages'
            );
            this.flashes.addSuccess('Package saved!');
          })
          .catch(() => {
            this.flashes.addError('Something went wrong. Please try again.');
          });
      } else {
        this.showValidations = true;
      }
    });
  }

  @action
  cancelPackage() {
    this.package.rollbackAttributes();
    this.router.transitionTo(
      'authenticated.admin.general-price-lists.edit.packages'
    );
  }
}
